const TechList = ({ length, tech, i, filters, setFilters }) => {
  const addFilter = (tech) => {
    if (!filters.includes(tech)) setFilters([...filters, tech]);
  };

  return (
    <>
      <span onClick={() => addFilter(tech)}>{tech}</span>
      {i < length - 1 ? <> ﹅ </> : <></>}
    </>
  );
};

export default TechList;
