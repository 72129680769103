import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Gallery from "./components/Gallery";
import Slideshow from "./components/Slideshow";
import memoji from "./assets/memoji-p.png";
import projects from "./projects";
import FilterList from "./components/FilterList";

const Portfolio = () => {
  const [portfolioView, setPortfolioView] = useState("gallery");
  const [showGallery, setShowGallery] = useState(true);
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [projectList, setProjectList] = useState([...projects]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (filters.length) {
      const filteredList = projects.filter((project) =>
        filters.every((filter) => project.tech.includes(filter))
      );
      setProjectList([...filteredList]);
    } else setProjectList([...projects]);
  }, [filters]);

  useEffect(() => {
    if (portfolioView === "gallery") {
      setShowGallery(true);
      setShowSlideshow(false);
    } else {
      setShowGallery(false);
      setShowSlideshow(true);
    }
  }, [portfolioView]);

  return (
    <div className="portfolio-container">
      <PortfolioHeader
        portfolioView={portfolioView}
        setPortfolioView={setPortfolioView}
        filters={filters}
        setFilters={setFilters}
      />
      {showGallery && (
        <Gallery
          projects={projectList}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {showSlideshow && (
        <Slideshow
          projects={projectList}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </div>
  );
};

const PortfolioHeader = ({
  portfolioView,
  setPortfolioView,
  filters,
  setFilters,
}) => {
  const gallery = portfolioView === "gallery" ? "pressed" : "";
  const slideshow = portfolioView === "slideshow" ? "pressed" : "";
  return (
    <div>
      <div className="header">
        <div>
          <span>Portfoli</span>
          <Link to="/" className="memoji memoji-p">
            <img src={memoji} alt="memoji" />
          </Link>
        </div>
        <div className="views">
          <p
            className={`grid ${gallery}`}
            onClick={() => setPortfolioView("gallery")}
          >
            <Icon icon="bxs:grid-alt" />
          </p>
          <p
            className={`stories ${slideshow}`}
            onClick={() => setPortfolioView("slideshow")}
          >
            <Icon icon="bxs:carousel" />
          </p>
        </div>
      </div>
      <div className="filters">
        {filters.map((filter) => {
          return (
            <FilterList
              filter={filter}
              filters={filters}
              setFilters={setFilters}
              key={filter}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
