import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import memoji from "./assets/memoji.png";

const Home = () => {
  const [showPortfolioBtn, setShowPortfolioBtn] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const buttonDelay = setTimeout(() => {
      setShowPortfolioBtn("show");
    }, 600);
    return () => clearTimeout(buttonDelay);
  });

  return (
    <div className="home-container">
      <div className="name">
        <span>Sarah L</span>
        <div className="memoji">
          <img src={memoji} alt="memoji" />
        </div>
        <span>pez</span>
      </div>
      <button
        className={showPortfolioBtn}
        onClick={() => navigate("/portfolio")}
      >
        portfolio
      </button>
    </div>
  );
};

export default Home;
