import { Icon } from "@iconify/react";

const FilterList = ({ filter, filters, setFilters }) => {
  const removeFilter = (filter) => {
    const filterCopy = filters.filter((item) => item !== filter);
    setFilters([...filterCopy]);
  };

  return (
    <div className="filter-item" onClick={() => removeFilter(filter)}>
      <span>{filter}</span>
      <Icon icon="ci:off-close" />
    </div>
  );
};

export default FilterList;
