import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import TechList from "./TechList";
import { useSwipeable } from "react-swipeable";

const Slideshow = ({ projects, filters, setFilters }) => {
  const [currentProject, setCurrentProject] = useState(0);

  const prevProject = () => {
    if (currentProject === 0) {
      setCurrentProject(projects.length - 1);
    } else {
      setCurrentProject(currentProject - 1);
    }
  };

  const nextProject = () => {
    if (currentProject === projects.length - 1) {
      setCurrentProject(0);
    } else {
      setCurrentProject(currentProject + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextProject(),
    onSwipedRight: () => prevProject(),
  });

  useEffect(() => {
    setCurrentProject(0);
  }, [filters]);

  return (
    <div className="slideshow" {...handlers}>
      <div className="slideshow-main">
        <span className="arrow" onClick={() => prevProject()}>
          <Icon icon="bxs:left-arrow-circle" />
        </span>
        <SlideshowItem
          projects={projects}
          currentProject={currentProject}
          filters={filters}
          setFilters={setFilters}
        />
        <span className="arrow" onClick={() => nextProject()}>
          <Icon icon="bxs:right-arrow-circle" />
        </span>
      </div>
      <div className="pages">
        {projects.map((project, index) => {
          return (
            <PageDots
              project={project}
              index={index}
              currentProject={currentProject}
              setCurrentProject={setCurrentProject}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

const SlideshowItem = ({ projects, currentProject, filters, setFilters }) => {
  return (
    <div className="slideshow-item">
      <img
        src={projects[currentProject].image}
        alt={projects[currentProject].title}
        onClick={() => window.open(projects[currentProject].url, "_blank")}
      />
      <div className="slideshow-details">
        <div
          className="title"
          onClick={() => window.open(projects[currentProject].url, "_blank")}
        >
          {projects[currentProject].title}
        </div>
        <div
          className="desc"
          onClick={() => window.open(projects[currentProject].url, "_blank")}
        >
          {projects[currentProject].description}
        </div>
        <div className="tech-list">
          {projects[currentProject].tech.map((tech, i) => {
            return (
              <TechList
                length={projects[currentProject].tech.length}
                tech={tech}
                i={i}
                filters={filters}
                setFilters={setFilters}
                key={`${projects[currentProject].title}-${tech}`}
              />
            );
          })}
        </div>
      </div>
      <Icon
        icon="akar-icons:github-fill"
        className="git"
        onClick={() => window.open(projects[currentProject].repo, "_blank")}
      />
    </div>
  );
};

const PageDots = ({ index, currentProject, setCurrentProject }) => {
  const pressed = currentProject === index ? "pressed" : "";
  return (
    <span className={`dot ${pressed}`} onClick={() => setCurrentProject(index)}>
      ●
    </span>
  );
};

export default Slideshow;
