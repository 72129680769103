const projects = [
  {
    title: "Word!",
    image: require("./assets/word.png"),
    url: "https://word.dailybrainplay.com",
    description: "Daily word guessing game",
    repo: "https://github.com/slopez323/word-tester",
    tech: ["HTML", "CSS", "JavaScript", "jQuery"],
  },
  {
    title: "myGas",
    image: require("./assets/mygas.png"),
    url: "https://mygas.sarahlopez.me/",
    description: "Find gas stations with user-updated prices",
    repo: "https://github.com/slopez323/gas-frontend",
    tech: [
      "HTML",
      "CSS",
      "JavaScript",
      "NodeJS",
      "ReactJS",
      "ExpressJS",
      "MongoDB",
    ],
  },
  {
    title: "Yums",
    image: require("./assets/yums.png"),
    url: "https://yums.sarahlopez.me/",
    description: "Restaurant diary",
    repo: "https://github.com/slopez323/yums-front",
    tech: [
      "HTML",
      "CSS",
      "JavaScript",
      "NodeJS",
      "ReactJS",
      "ExpressJS",
      "MongoDB",
    ],
  },
  {
    title: "Wordle Helper",
    image: require("./assets/wordle-helper.png"),
    url: "https://wordle-helper.sarahlopez.me",
    description: "Lists all remaining possible words for NYT's Wordle",
    repo: "https://github.com/slopez323/wordle-helper",
    tech: ["HTML", "CSS", "JavaScript", "jQuery"],
  },
  {
    title: "Wordle Clone",
    image: require("./assets/wordle-clone.png"),
    url: "https://wordle-clone.sarahlopez.me",
    description: "Recreation of NYT's Wordle",
    repo: "https://github.com/slopez323/react-wordle",
    tech: ["HTML", "CSS", "JavaScript", "NodeJS", "ReactJS"],
  },
  {
    title: "Tic Tac Toe",
    image: require("./assets/tictactoe.png"),
    url: "https://tictactoe.sarahlopez.me",
    description: "Single or 2-player tic tac toe game",
    repo: "https://github.com/slopez323/tictactoe",
    tech: ["HTML", "CSS", "JavaScript", "jQuery"],
  },
  {
    title: "To Do List",
    image: require("./assets/todo.png"),
    url: "https://todo.sarahlopez.me",
    description: "Task tracker by project/category",
    repo: "https://github.com/slopez323/todolist",
    tech: ["HTML", "CSS", "JavaScript", "NodeJS"],
  },
  {
    title: "Library",
    image: require("./assets/library.png"),
    url: "https://library.sarahlopez.me",
    description: "Stores details of favorite books",
    repo: "https://github.com/slopez323/library",
    tech: ["HTML", "CSS", "JavaScript", "jQuery"],
  },
  {
    title: "Calculator",
    image: require("./assets/calc.png"),
    url: "https://calculator.sarahlopez.me",
    description: "Simple calculator",
    repo: "https://github.com/slopez323/calculator",
    tech: ["HTML", "CSS", "JavaScript"],
  },
];

export default projects;
