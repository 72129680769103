import { Icon } from "@iconify/react";
import TechList from "./TechList";

const Gallery = ({ projects, filters, setFilters }) => {
  return (
    <div className="gallery">
      {projects.map((project) => {
        return (
          <ProjectGallery
            project={project}
            filters={filters}
            setFilters={setFilters}
            key={project.title}
          />
        );
      })}
    </div>
  );
};

const ProjectGallery = ({ project, filters, setFilters }) => {
  return (
    <div className="project">
      <img
        src={project.image}
        alt={project.title}
        onClick={() => window.open(project.url, "_blank")}
      />
      <div className="details" onClick={() => window.open(project.url)}>
        <div className="title">{project.title}</div>
        <div className="desc">{project.description}</div>
      </div>
      <div className="tech-list">
        {project.tech.map((tech, i) => {
          return (
            <TechList
              length={project.tech.length}
              tech={tech}
              i={i}
              filters={filters}
              setFilters={setFilters}
              key={`${project.title}-${tech}`}
            />
          );
        })}
      </div>
      <Icon
        icon="akar-icons:github-fill"
        className="git"
        onClick={() => window.open(project.repo, "_blank")}
      />
    </div>
  );
};

export default Gallery;
